import axios, { AxiosResponse, AxiosError } from 'axios';
import { IQrCodes } from 'models';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import api from '../http';

const apiGetFiles = axios.create({
    baseURL: '/api/',
});

const refreshAuthLogic = (failedRequest: AxiosError) =>
    api
        .post('/auth/refresh/', {
            value: localStorage.getItem('refreshToken'),
        })
        .then((response) => {
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            const newRequest = failedRequest;
            newRequest.response.config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            return Promise.resolve();
        });

createAuthRefreshInterceptor(apiGetFiles, refreshAuthLogic);

api.interceptors.request.use((res) => {
    if (res.headers) {
        res.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return res;
});

export default class QrCodesService {
    static async getQrFiles(): Promise<AxiosResponse<IQrCodes[]>> {
        return apiGetFiles.get(`/s3/folder/temporary-qr-codes-result/files`);
    }

    static async uploadFile(file: File): Promise<AxiosResponse<void>> {
        const formData = new FormData();
        formData.append('file', file);

        return api.post(`/qr/code/temporary`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}
