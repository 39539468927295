import { makeAutoObservable } from 'mobx';
import { IError, IQrCodes } from 'models';
import QrCodesService from 'services/QrCodesService';

class QrStore {
    isLoading = false;
    qrFiles: IQrCodes[] | null = null;
    errorQr: IError | null = null;
    errorUpload: IError | null = null;

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setQrFiles(data: IQrCodes[]) {
        this.qrFiles = data;
    }

    setError(data: IError | null) {
        this.errorQr = data;
    }

    setAddError(data: IError | null) {
        this.errorUpload = data;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getQrFiles() {
        this.setIsLoading(true);
        try {
            this.setError(null);
            const response = await QrCodesService.getQrFiles();
            this.setQrFiles(response.data);
        } catch (error: any) {
            if (error && error?.response && error?.response?.data) {
                this.setError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }

    async uploadQrFile(file: File) {
        this.setIsLoading(true);
        try {
            this.setAddError(null);
            await QrCodesService.uploadFile(file);
            this.getQrFiles();
        } catch (error: any) {
            if (error?.response?.data) {
                this.setAddError(error.response.data);
            }
        }
        this.setIsLoading(false);
    }
}

const qrStore = new QrStore();

export default qrStore;
