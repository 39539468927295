/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Table } from 'components/layout';
import { ColumnsType } from 'antd/lib/table';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { IQrCodes } from 'models';

import './qrcodes-table.scss';

const { Item } = Menu;

interface Props {
    data?: IQrCodes[] | null;
    loading: boolean;
    downloadQrFile: (link: string) => void;
}

const QrCodesTable: FC<Props> = (props: Props) => {
    const { data, loading, downloadQrFile } = props;
    const columns: ColumnsType<IQrCodes> = [
        {
            title: 'Идентификатор файла',
            className: 'event-table-name',
            dataIndex: 'id',
            width: '15%',
        },
        {
            title: 'Название файла',
            className: 'event-table-name',
            dataIndex: 'originalFileName',
            width: '15%',
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            render: (_: string, row: IQrCodes) => (
                <Dropdown
                    className="dropdown"
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Item onClick={() => downloadQrFile(row.link)}>Скачать</Item>
                        </Menu>
                    }
                >
                    <Space>
                        Действия
                        <DownOutlined />
                    </Space>
                </Dropdown>
            ),
            width: '8%',
        },
    ];
    return (
        <Table
            className="qrCodes-table"
            data={data}
            columns={columns}
            loading={loading}
            page={0}
            onChangePage={() => console.log()}
        />
    );
};

export default QrCodesTable;
