import React, { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { IRoute } from 'models';
import { Button, message, Upload } from 'antd';
import { EmptySearch, ErrorRequest, Header, Icons, Layout } from 'components/layout';
import qrStore from 'store/QrStore';
import strings from 'tools';
import QrCodesTable from 'components/qrcodes';

import './qrcodes.scss';

interface Props {
    navigation: IRoute[];
}

const QrCodes: FC<Props> = observer((props: Props) => {
    const { navigation } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const { qrFiles, isLoading } = qrStore;

    const handleUpload = async ({ file }: { file: File }) => {
        try {
            await qrStore.uploadQrFile(file);
            qrStore.getQrFiles();
        } catch (error) {
            message.error('Ошибка загрузки:', error);
        }
    };

    useEffect(() => {
        qrStore.getQrFiles();
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const downloadQrFile = (link: string) => {
        if (link) {
            window.open(link, '_blank');
        }
    };

    return (
        <Layout page="qrcodes" navigation={navigation} errorScreen>
            <Header
                title="QR-коды"
                description={
                    qrFiles
                        ? `Всего ${qrFiles.length} ${strings.ending(qrFiles.length, [
                              'QR-код',
                              'QR-кода',
                              'QR-кодов',
                          ])}`
                        : ''
                }
            />
            <div className="page-offers">
                <div className="offers-top">
                    <Upload
                        accept=".csv, .xml"
                        beforeUpload={(file) => {
                            handleUpload({ file });
                            return false;
                        }}
                        showUploadList={false}
                    >
                        <Button type="primary">{Icons.plus()}Создать QR-коды</Button>
                    </Upload>
                </div>
                {qrFiles?.length && (qrFiles?.length > 0 || isLoading) && (
                    <QrCodesTable
                        data={qrFiles}
                        loading={isLoading}
                        downloadQrFile={downloadQrFile}
                    />
                )}
                {!qrFiles?.length && !isLoading && <EmptySearch />}
                {!qrFiles && <ErrorRequest onClick={() => qrStore.getQrFiles()} />}
            </div>
        </Layout>
    );
});

export default QrCodes;
