import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const API_URL = '/api/admin/';

const api = axios.create({
    baseURL: API_URL,
});

const clearAndRedirect = () => {
    message.error('Вы не авторизованны!');
    localStorage.removeItem(`accessToken`);
    localStorage.removeItem(`refreshToken`);
    window.location.href = '/login';
};

const refreshAuthLogic = (failedRequest: AxiosError) =>
    api
        .post('/auth/refresh/', {
            value: localStorage.getItem('refreshToken'),
        })
        .then((response) => {
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            const newRequest = failedRequest;
            newRequest.response.config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            return Promise.resolve();
        })
        .catch(() => {
            clearAndRedirect();
        });

createAuthRefreshInterceptor(api, refreshAuthLogic);

api.interceptors.request.use((res) => {
    if (res.headers) {
        res.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    return res;
});

api.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        if (error.response?.status === 400) {
            const { title } = error.response.data as { title: string };
            message.error(title || 'Ошибка 400 bad request');
        }
        if (error.response?.status === 403) {
            message.error('Недостаточно прав');
        }
        if (error.response?.status === 500) {
            message.error('Нет соединения с сервером. Попробуйте обновить страницу');
        }
        if (error.code === 'ERR_NETWORK') {
            message.error(
                'Нет соединения с сетью. Проверьте соединение или попробуйте подключиться позже'
            );
        }
        return Promise.reject(error);
    }
);

export default api;
